<template>
  <modify-apply-block
    data-type="worker_certificate_other"
    ref="worker_certificate_other_p"
    :getTitle="getTitle"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :form-parms-add="formParmsAdd"
    :dataFileParm="{docName: '其他证书', isDoc: true}"
    block-name="其他证书"
    @dataFileUpdate="dataFileUpdate"
    :init-add-form-data="initAddFormData"
    ref-name="worker_certificate_other"
    :worker-id="workerId">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  workerCertificateRequest
} from '../../../api'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {
    ModifyApplyBlock
  },
  data () {
    return {
      workerId: null
    }
  },
  created () {
    this.workerId = this.$router.history.current.query.workerId
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs['worker_certificate_other_p'].openForm) {
      this.$refs['worker_certificate_other_p'].openForm = false
      next(false)
    } else {
      next()
    }
  },
  methods: {
    async getEdit () {
      if (this.workerId) {
        return await workerCertificateRequest.getEdit({
          type: 'other',
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      return await workerCertificateRequest.saveEdit(Object.assign({}, parm, {
        dataId: parm.id,
        id: undefined
      }))
    },
    async addEdit (parm) {
      return await workerCertificateRequest.addEdit(parm)
    },
    async dataFileUpdate (parm, pageVm) {
      await workerCertificateRequest.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {
        workerId: this.workerId,
        type: 'other'
      }
    },
    getTitle (data) {
      return data.mType || ''
    }
  },
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '证书名称',
          key: 'mType'
        },
        {
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'input',
          label: '证件状态',
          key: 'status'
        }]
        return data
      }
    }
  }
}
</script>
